import styled from "styled-components";
import ModalBg from "../../assets/images/modal-home-background.jpg"

const ModalHomeDiv = styled.div`
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &:before {
    background-color: ${({ theme }) => theme.black};
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.ocult {
    display: none;
  }
`;

const ModalHomeContentDiv = styled.div`
  width: 37.5rem;
  height: 30.19rem;
  padding: 30px 60px 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background: url(${ModalBg}) no-repeat center;
  background-size: cover;

  button {
    background-color: ${({ theme }) => theme.white_900};
    border: 2px ${({ theme }) => theme.white_900};
    border-radius: 5px;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-left: auto;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    .icon-times {
      &:before {
        font-size: 23px;
        color: ${({ theme }) => theme.green_900};
      }
    }
  }
  
  @media only screen and (max-width : 1023px) {
    width: 75%;
    max-height: 505px;
  }

  @media only screen and (max-width : 767px) {
    width: 85%;
    padding-bottom: 80px;
  }

  @media only screen and (max-width : 419px) {
    max-height: 305px;
    padding: 30px 30px 40px 20px;

    button {
      top: 20px;
      right: 30px;

      .icon-times {
        &:before {
          font-size: 18px;
        }
      }
    }
  }
`;

const ModalHomeTextDiv = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 40px;

  span {
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }

  a {
    width: fit-content;
    font-weight: 600;
    color: #FFFFFF;
  }

  @media only screen and (max-width : 419px) {
    font-size: 14px;
    gap: 20px;
  }
`;

export { ModalHomeDiv, ModalHomeContentDiv, ModalHomeTextDiv };
