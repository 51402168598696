import React, { FC, useState } from "react";
import { ModalHomeDiv, ModalHomeContentDiv, ModalHomeTextDiv } from "./styles";

const ModalHome: FC = () => {
  const [isClassApplied, setIsClassApplied] = useState(false);

  // Função para aplicar a classe
  const handleClick = () => {
    console.log("Close button clicked");
    setIsClassApplied(true);
  };

  return (
    <ModalHomeDiv className={isClassApplied ? 'ocult' : ''}>
      <ModalHomeContentDiv>
        <button onClick={handleClick}>
          <span className="icon icon-times"></span>
        </button>
        
        <ModalHomeTextDiv>
          <span>Comunicado Oficial</span>

          <p>
            A Enauta e a 3R Petroleum concluíram o processo de fusão das duas Companhias. Juntas, as empresas se transformam em uma das maiores e mais diversificadas independentes de petróleo e gás do país.
          </p>

          <p>
            Para acessar o site da Enauta, <a href="https://www.enauta.com.br/" target="_blank">clique aqui</a>.
          </p>
        </ModalHomeTextDiv>
      </ModalHomeContentDiv>
    </ModalHomeDiv>
  );
};

export default ModalHome;