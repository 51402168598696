import React, { FC, useState, useMemo } from 'react';
import DOMPurify from 'dompurify';

import { ContainerMinDiv } from '../../layout/Container/styles';
import { ButtonDefaultLink } from '../Form/Button/styles';
import { H2, H2Text, H2Title } from '../Typography/styles';
import { MessageDiv, ManualDiv } from './styles';

import Checkbox from '../Checkbox';
import pdf from './pdf.png';

// message
const Message: FC<any> = ({
	title,
	subtitle,
	text,
	footer,
	link,
	checkboxText,
	manual,
	manual2,
	manual3,
	manual4,
	manual5,
	manual6,
	manual7,
	manual8,
	manual9,
}) => {
	const [isCheckboxChecked, setCheckboxChecked] = useState(false)

	const handleClick = (checked: any) => {
		setCheckboxChecked(checked)
	}

	const itemClass = useMemo(() => {
		return isCheckboxChecked ? '' : 'disabled'
	}, [isCheckboxChecked])

	const manualSanitizedData = (data: string) => ({
		__html: DOMPurify.sanitize(data),
	})

	return (
		<MessageDiv className='message'>
			<ContainerMinDiv>
				{title ? <H2 className='message--title'>{title}</H2> : ""}
				{subtitle ? <H2Title className='message--subtitle'>{subtitle}</H2Title> : ""}

				{text ? <H2Text className='message--text'>{text}</H2Text> : ""}
				{footer ? <H2Text className='message--footer'>{footer}</H2Text> : ""}

				{manual ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual)}
					></p>
				</ManualDiv>
				: ""}

				{manual2 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual2)}
					></p>
				</ManualDiv>
				: ""}

				{manual3 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual3)}
					></p>
				</ManualDiv>
				: ""}
				
				{manual4 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual4)}
					></p>
				</ManualDiv>
				: ""}

				{manual5 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual5)}
					></p>
				</ManualDiv>
				: ""}
				
				{manual6 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual6)}
					></p>
				</ManualDiv>
				: ""}
				
				{manual7 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual7)}
					></p>
				</ManualDiv>
				: ""}

				{manual8 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual8)}
					></p>
				</ManualDiv>
				: ""}

				{manual9 ?
				<ManualDiv>
					<img className='manual--image' src={pdf} alt='PDF' />
					<p
						className='manual--text'
						dangerouslySetInnerHTML={manualSanitizedData(manual9)}
					></p>
				</ManualDiv>
				: ""}
				
				{checkboxText ? 
				<Checkbox
					text={checkboxText}
					onChange={handleClick}
					checked={isCheckboxChecked}
				></Checkbox>
				: ""}
				
				{link ?
				<ButtonDefaultLink
					href='https://forms.office.com/r/AX4pYuA8mC'
					target='_blank'
					rel='noopener noreferrer'
					className={itemClass}
				>
					{link}
				</ButtonDefaultLink>
				: ""}
			</ContainerMinDiv>
		</MessageDiv>
	)
}

export default Message;
